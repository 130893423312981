<nav class=" navbar navbar-expand-lg navbar-absolute"
  [ngClass]="{'bg-white': isCollapsed === false, 'navbar-transparent': isCollapsed === true}">
  <div class=" container-fluid">
    <div class=" navbar-wrapper">
      <div class=" navbar-minimize d-inline">
        <button class=" minimize-sidebar btn btn-link btn-just-icon" tooltil="Sidebar toggle" placement="right"
          (click)="minimizeSidebar()">
          <i class=" tim-icons icon-align-center visible-on-sidebar-regular">
          </i>
          <i class=" tim-icons icon-bullet-list-67 visible-on-sidebar-mini">
          </i>
        </button>
      </div>
      <div class=" navbar-toggle d-inline">
        <button class=" navbar-toggler" type="button" (click)="sidebarOpen()">
          <span class=" navbar-toggler-bar bar1"></span>
          <span class=" navbar-toggler-bar bar2"> </span>
          <span class=" navbar-toggler-bar bar3"> </span>
        </button>
      </div>
      <a class=" navbar-brand" href="javascript:void(0)"> {{ title }} </a>
    </div>
    <button aria-label="Toggle navigation" class=" navbar-toggler" [attr.aria-expanded]="!isCollapsed"
      (click)="isCollapsed = !isCollapsed" id="navigation" type="button">
      <span class=" navbar-toggler-bar navbar-kebab"> </span>
      <span class=" navbar-toggler-bar navbar-kebab"> </span>
      <span class=" navbar-toggler-bar navbar-kebab"> </span>
    </button>
    <div class=" navbar-collapse" [collapse]="isCollapsed" id="navigation">

      <ul class=" navbar-nav ml-auto">
        
          <span class="magis-navbar-text" style="margin-right: 10px;padding-top : 15px">
            Welcome, <b>{{user_fullname}}</b>!</span>
        
        <!-- <li class=" dropdown nav-item" dropdown>
            <a
              class=" dropdown-toggle nav-link dropdown-toggle"
              data-toggle="dropdown"
              dropdownToggle
              href="javascript:void(0)"
              (click)="$event.preventDefault()"
            >
              <div class=" notification d-none d-lg-block d-xl-block"></div>
              <i class=" tim-icons icon-sound-wave"> </i>
              <p class=" d-lg-none">Notifications</p>
            </a>
            <ul
              class=" dropdown-menu dropdown-menu-right dropdown-navbar"
              *dropdownMenu
            >
              <li class=" nav-link">
                <a class=" nav-item dropdown-item" href="#">
                  Mike John responded to your email
                </a>
              </li>
              <li class=" nav-link">
                <a class=" nav-item dropdown-item" href="javascript:void(0)">
                  You have 5 more tasks
                </a>
              </li>
              <li class=" nav-link">
                <a class=" nav-item dropdown-item" href="javascript:void(0)">
                  Your friend Michael is in town
                </a>
              </li>
              <li class=" nav-link">
                <a class=" nav-item dropdown-item" href="javascript:void(0)">
                  Another notification
                </a>
              </li>
              <li class=" nav-link">
                <a class=" nav-item dropdown-item" href="javascript:void(0)">
                  Another one
                </a>
              </li>
            </ul>
          </li> -->
          <li class="dropdown nav-item" dropdown placement="bottom left">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              dropdownToggle
              href="#"
              (click)="$event.preventDefault()"
            >
              <div class=" photo">
                <img alt="Profile Photo" src="assets/img/default-avatar.png" />
              </div>
              <b class=" caret d-none d-lg-block d-xl-block"> </b>
              <p class=" d-lg-none">Log out</p>
            </a>
            <ul
              class=" dropdown-menu dropdown-navbar dropdown-menu-right"
              *dropdownMenu
            >
              <li class=" nav-link">
                <a class=" nav-item dropdown-item" [routerLink]="['admin/profile']">
                  Profile
                </a>
              </li>
              <li class=" nav-link">
                <a class=" nav-item dropdown-item" [routerLink]="['admin/setting']">
                  Settings
                </a>
              </li>
              <li class=" dropdown-divider"></li>
              <li class=" nav-link">
                <a class=" nav-item dropdown-item" (click)="logout()">
                  Sign out
                </a>
              </li>
            </ul>
          </li>
          <li class=" separator d-lg-none"></li>
        </ul>
      </div>
    </div>
  </nav>
  <div
    aria-hidden="true"
    aria-labelledby="searchModal"
    bsModal
    class=" modal modal-search fade"
    #searchModal="bs-modal"
    id="searchModal"
    role="dialog"
    tabindex="-1"
  >
    <div class=" modal-dialog" role="document">
      <div class=" modal-content">
        <div class=" modal-header">
          <input
            class=" form-control"
            id="inlineFormInputGroup"
            placeholder="SEARCH"
            type="text"
          />
          <button
            aria-label="Close"
            class=" close"
            (click)="searchModal.hide()"
            data-dismiss="modal"
            type="button"
          >
            <i class=" tim-icons icon-simple-remove"> </i>
          </button>
        </div>
      </div>
    </div>
  </div>
