import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  countdown: number | null = null;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router
  ) {
    idle.setIdle(900); // 5 minutes of inactivity
    idle.setTimeout(30); // 30 seconds timeout warning

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
    });

    idle.onTimeout.subscribe(() => {
      this.timedOut = true;

      localStorage.setItem('lastRoute', this.router.url);

      localStorage.clear();
      this.router.navigate(['/account/sign-in']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = `You will be logged out in ${countdown} seconds due to inactivity!`;
      this.countdown = countdown;
    });

    // Keepalive settings
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnInit(): void {
    // Retrieve the last route if present
    const lastRoute = localStorage.getItem('lastRoute');
    if (lastRoute) {
      this.router.navigateByUrl(lastRoute);
      localStorage.removeItem('lastRoute');
    }
  }
}
