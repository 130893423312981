import { Component, OnInit } from '@angular/core';

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  type?: string;
  collapse?: string;
  icontype?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: 'admin/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'fas fa-th fa-xs',
  },
  {
    path: '/admin',
    title: 'Manage',
    type: 'sub',
    icontype: 'fas fa-user-cog',
    collapse: 'pages',
    isCollapsed: true,
    children: [
      {
        path: 'client',
        title: 'Clients',
        type: 'link',
        icontype: 'fas fa-users',
        smallTitle: 'P',
      },
      {
        path: 'administrator',
        title: 'Administrators',
        type: 'link',
        icontype: 'fas fa-user-secret',
        smallTitle: 'RS',
      },


      {
        path: 'permissions',
        title: 'Permissions',
        type: 'link',
        icontype: 'fas fa-lock',
        smallTitle: 'RS',
      },
      {
        path: 'activity-log',
        title: 'User Activities',
        icontype: 'fas fa-user-check',
        type: 'link',
        smallTitle: 'RS',
      },
    ],
  },
  {
    path: '/admin',
    title: 'Solution Management',
    type: 'sub',
    icontype: 'fas fa-portrait',
    collapse: 'pages',
    isCollapsed: true,
    children: [
      {
        path: 'funeral-quote',
        title: 'Funeral Quotes',
        icontype: 'fas fa-file-medical',
        type: 'link',
        smallTitle: 'RS',
      },
      {
        path: 'motor-quote',
        title: 'Motor Quotes',
        icontype: 'fas fa-car',
        type: 'link',
        smallTitle: 'P',
      },
      {
        path: 'funeral-policies',
        title: 'Funeral Policies',
        icontype: 'fas fa-notes-medical',
        type: 'link',
        smallTitle: 'RS',
      },
      {
        path: 'motor-policies',
        title: 'Motor Policies',
        icontype: 'fas fa-car-crash',
        type: 'link',
        smallTitle: 'P',
      },
      {
        path: 'claims',
        title: 'Claims',
        icontype: 'fas fa-hand-holding-heart',
        type: 'link',
        smallTitle: 'P',
      },
      // {
      //   path: "ticket-management",
      //   title: "Tickets",
      //   icontype: "fas fa-ticket-alt",
      //   type: "link",
      //   smallTitle: "P"
      // },
    ],
  },
  {
    path: '/admin',
    title: 'Solution Setup',
    type: 'sub',
    icontype: 'fas fa-house-user',
    collapse: 'pages',
    isCollapsed: true,
    children: [
      {
        path: 'insurance-provider',
        title: 'Insurance Providers',
        type: 'link',
        icontype: 'fas fa-user-shield',
        smallTitle: 'RS',
      },
      {
        path: 'benefits',
        title: 'Benefits',
        icontype: 'fas fa-file-alt',
        type: 'link',
        smallTitle: 'RS',
      },
      {
        path: 'solutions',
        title: 'Solutions',
        icontype: 'fas fa-file-alt',
        type: 'link',
        smallTitle: 'RS',
      },
      {
        path: 'recommendation-rule',
        title: 'Recommendation Rules',
        type: 'link',
        icontype: 'fas fa-book',
        smallTitle: 'RS',
      },
      {
        path: 'rating-table',
        title: 'Rating Tables',
        icontype: 'fas fa-receipt',
        type: 'link',
        smallTitle: 'RS',
      },
      // {
      //   path: 'quote-parameter',
      //   title: 'Quote Parameters',
      //   icontype: 'fas fa-file-alt',
      //   type: 'link',
      //   smallTitle: 'P',
      // },
    ],
  },
  {
    path: '/admin',
    title: 'Support',
    type: 'sub',
    icontype: 'fas fa-headset',
    collapse: 'pages',
    isCollapsed: true,
    children: [
      {
        path: 'faq-help',
        title: 'FAQ / Help',
        type: 'link',
        icontype: 'fas fa-question-circle',
        smallTitle: 'RS',
      },
      {
        path: 'ticket-management',
        title: 'Tickets',
        type: 'link',
        icontype: 'fas fa-ticket-alt',
      },
    ],
  },
  {
    path: 'admin/setting',
    title: 'Settings',
    type: 'link',
    icontype: 'fas fa-cog',
  },
  {
    path: '',
    title: 'Sign out',
    type: 'link',
    icontype: 'fas fa-sign-out-alt',
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES?.filter((menuItem) => menuItem);
  }
}
