import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})

export class Notifications {
  constructor(public toastr: ToastrService) { }
  
  showNotification(message: any, title:any,type: any) {
    // close all open notifications


    if(type == "danger")
    {
      this.toastr.error(message,title,{
            timeOut: 5000,
            enableHtml: true,
            closeButton: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-center"
      })
    }

    if(type == "success")
    {
      this.toastr.error(message,title,{
            timeOut: 5000,
            enableHtml: true,
            closeButton: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-center"
      })
    }
  }
  
  
}