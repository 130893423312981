import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsModalService, ModalModule } from "ngx-bootstrap/modal";
import { DxVectorMapModule } from "devextreme-angular";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { TokenHttpInterceptor } from '../Interceptors/token-http.interceptor';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';




@NgModule({
  declarations: [
    AuthNavbarComponent,
    SidebarComponent,
    NavbarComponent,
    ComingSoonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    JwBootstrapSwitchNg2Module,
    ReactiveFormsModule,
    DxVectorMapModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot()
  ],
  exports:[
    AuthNavbarComponent,
    ReactiveFormsModule,
    SidebarComponent,
    NavbarComponent,
    ComingSoonComponent
  ],
  providers:[BsModalService,
    {

      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpInterceptor,
      multi: true,
  },
  ]
})
export class ComponentsModule { }
