<div class=" content">
    <div class=" row mt-5">
        <div class=" col-md-12">
            <div class=" card">
                <div class="card-body mt-4">
                    <div class="d-flex justify-content-center mt-4">
                        <img class="logo-image" src="../../../assets/logos/magis-logo3.png">
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <h1>Coming soon!</h1>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
