import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    roleAs: string;

    constructor(
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = localStorage.getItem("token");

        if (token) {
            return true;
        }
        
        this.router.navigate(['/account/sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    getRole() {
        this.roleAs = localStorage.getItem('role');
        return this.roleAs;
      }
}