import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IForgotPasswordModel, IForgotPasswordResetModel } from '../models/forgot-password-model';
import { ILoginModel } from '../models/login.model';
import { IUserDetails, IUserRequest } from '../models/user-details';
import { IResponseStatus } from '../models/response-status';
import { ITokenResponse } from '../models/token.model';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    relatePath: string = 'admin/v1/';
    relatePathAuth: string = 'auth/v1/';

    constructor(private readonly _http: HttpClient) { }

    public async login(model: ILoginModel): Promise<ITokenResponse> {
        try {
            const response = await this._http
                .post<ITokenResponse>(`${environment.serverApiUrl}` + this.relatePathAuth + `login`, model)
                .toPromise()
            return response;

        } catch (error) {
            throw new Error('A connection could not be established. Please contact an administrator.');
        }
    }
    public async submitForgotPasswordRequest(model: IForgotPasswordModel): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}` + this.relatePathAuth + `registration/reset-password`, model)
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async submitForgotPasswordUpdate(model: IForgotPasswordResetModel): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}` + this.relatePathAuth + `registration/reset-password-confirm`,
                    model
                )
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserProfile(): Promise<IUserDetails> {
        try {
            var response: any;

            let userDetailInStore = localStorage.getItem('userDetail');
            if (userDetailInStore !== 'undefined' && userDetailInStore !== null && userDetailInStore !== undefined) {
                response = JSON.parse(userDetailInStore);
            } else {
                response = await this._http
                    .post<IUserDetails>(
                        `${environment.serverApiUrl}` + this.relatePath +  `user-details/get-admin-details`,
                        null
                    )
                    .toPromise();
                localStorage.setItem("userDetail", JSON.stringify(response));
            }
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserProfile(user:IUserRequest): Promise<IResponseStatus> {
        try {
            var response: any;

                response = await this._http
                    .post<IResponseStatus>(
                        `${environment.serverApiUrl}` + this.relatePath +  `user-details/update-admin-user`,
                        user
                    )
                    .toPromise();            
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
