import { createReducer, on } from '@ngrx/store';
import * as FormActions from '../actions/policy.action';


export const initialPolicyState: any = {
  form: {},
  quoteId: "",
  userId:"",
  quoteDetails: "",
  documentToken:null
};


export const policyReducer = createReducer(
    initialPolicyState,
  on(FormActions.addItem, (state, { policyForm }) => ({
    ...state,
    formData: policyForm,
  })),
  on(FormActions.loadQuotePolicy, (state, { quote , quoteId , userId }) => ({
    ...state,
    quoteDetails: quote,
    quoteId:quoteId,
    userId : userId

  })),
  on(FormActions.loadPolicyDocument, (state, { documentToken }) => ({
    ...state,
    documentToken: documentToken,

  })),

  // Add more reducers as per your requirements
);
