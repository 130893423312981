import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Notifications } from '../components/notifications.component';

@Injectable({
  providedIn: 'root'
})
export class TokenHttpInterceptor implements HttpInterceptor {

  private loaderContainer: HTMLElement | null = null;
  private isLoaderVisible: boolean = false;

  constructor(private notification: Notifications, private route: Router) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token = localStorage.getItem("token");

    req = req.clone({
        setHeaders: {
            Authorization: `Bearer ` + token,
            'Ocp-Apim-Subscription-Key': '786124ad96554efc9fdd784a5388704a',
            'X-Channel': 'WEB',
        }
    });

    if (!this.isLoaderVisible) {
      this.showLoader();
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) { }
        return event;
      }),
      catchError((error: any) => {
        let data = {};
        data = {
          reason: error.message,
          status: error.status
        };

        if (error?.error?._embedded != undefined) {
          if (error.error._embedded.errors[0].message.indexOf('Required argument [Authentication') !== -1) {
              localStorage.removeItem('token');
              this.route.navigate(['/sign-in']);
          } else if(error?.status == HttpStatusCode.Unauthorized) {

              this.notification.showNotification(error.error.message,"System", 'danger');
          }else{
              this.notification.showNotification("A critical technical error has occurred. Contact support team or try again later.","System", 'danger');
          }
      } else {
          if (error?.status === HttpStatusCode.ServiceUnavailable) {
              this.notification.showNotification('Service currently unavailable',"System",'danger');
          } else if(error?.status == HttpStatusCode.Unauthorized) {

              this.notification.showNotification(error.error.message,"System", 'danger');
          }else{
              this.notification.showNotification("A critical technical error has occurred. Contact support team or try again later.","System", 'danger');
          }
      }

        return throwError(error);
      }),
      finalize(() => {
        this.hideLoader();
      })
    );
  }

  private showLoader() {
    // Create a loader container and append it to the document body
    this.loaderContainer = document.createElement('div');
    this.loaderContainer.className = 'loader-container';
    const loader = document.createElement('div');
    loader.className = 'loader';
    this.loaderContainer.appendChild(loader);
    document.body.appendChild(this.loaderContainer);
    this.isLoaderVisible = true;
  }

  private hideLoader() {
    // Remove the loader container from the document body
    if (this.loaderContainer && this.loaderContainer.parentNode) {
      this.loaderContainer.parentNode.removeChild(this.loaderContainer);
      this.loaderContainer = null;
      this.isLoaderVisible = false;
    }
  }
}
