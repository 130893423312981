import { createReducer, on } from '@ngrx/store';
import * as FormActions from '../actions/insure-quote.action';

export const initialQuoteState: any = {
  formData: {},
};

export const formReducer = createReducer(
  initialQuoteState,
  on(FormActions.addItem, (state, { insureForm }) => ({
    ...state,
    formData: insureForm,
  })),
  on(FormActions.deleteAll, (state) => ({
    ...state,
    formData: {},
  })),
);

export interface QuoteState {
  quotes: FormActions.Quote[];
  quote: FormActions.Quote | any;
  error: any;
}

export const initialState: QuoteState = {
  quotes: [],
  quote: null,
  error: null,
};

export const optionReducer = createReducer(
  initialState,

  on(FormActions.addQuote, (state, { quote }) => ({
    ...state,
    quote,
  })),
  on(FormActions.deleteQuote, (state) => ({
    ...state,
    quote: {},
  })),

  on(FormActions.editBenefitSuccess, (state, { quoteId, benefit }) => {
    return {
      ...state,
      quote: state.quote
        ? {
            ...state.quote,
            quoteOptions: state.quote.quoteOptions.map((option: any) =>
              option.motorDetails.id === quoteId
                ? {
                    ...option,
                    benefits: benefit,
                  }
                : option,
            ),
          }
        : null,
    };
  }),

  on(FormActions.removeBenefits, (state, { quoteId, benefitIndex }) => {
    return {
      ...state,
      quote: state.quote
        ? {
            ...state.quote,
            quoteOptions: state.quote.quoteOptions.map((option: any) =>
              option.motorDetails.id === quoteId
                ? {
                    ...option,
                    benefits: option?.benefits?.filter(
                      (_: any, index: number) => index !== benefitIndex
                    ),
                  }
                : option,
            ),
          }
        : null,
    };
  }),
  


  
);
