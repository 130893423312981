import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/_helpers/auth.guard';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
const routes: Routes = [
  {
    path: "",
    redirectTo: "/account/sign-in",
    pathMatch: "full"
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "account",
        loadChildren: () => import('./account/account.module').then(x=>x.AccountModule)
      },
    ]
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "admin",
        loadChildren: () => import('./admin/admin.module').then(x=>x.AdminModule)
      }
    ]
  },

  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "admin",
        loadChildren: () => import('./upload-quotes/upload-quotes.module').then(x=>x.UploadQuotesModule)
      }
    ]
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "admin",
        loadChildren: () => import('./recommendation-rule/recommendation-rule.module').then(x=>x.RecommnedationRuleModule)
      }
    ]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{
    initialNavigation: 'enabled',
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
