import { createReducer, on } from '@ngrx/store';
import { QuoteState } from '../states/quote.state';
import * as QuoteActions from '../actions/quote.actions';

export const initialQuoteState: QuoteState = {
  id: 0,
  quoteId: '',
  userId: '',
  quoteDetails: '',
  selectedQuote: null,
};

export const quoteReducer = createReducer(
  initialQuoteState,
  on(QuoteActions.loadQuoteSuccess, (state, { quote }) => ({
    ...state,
    selectedQuote: quote,
  })),
  // Add more reducers as per your requirements
);
